export default {
  async selectSolicitudMaterial (Vue, idsolicitudMaterial) {
    const tables = Vue.$offline.db.tables
    return (await Vue.$offline.db
      .select()
      .from(tables.solicitud_material)
      .leftOuterJoin(
        tables.grupo_empleado,
        tables.solicitud_material.idgrupo_empleado.eq(tables.grupo_empleado.idgrupo_empleado)
      )
      .where(
        Vue.$offline.db.op.and(
          tables.solicitud_material.idsolicitud_material.eq(idsolicitudMaterial),
          tables.solicitud_material.estado.gt(0),
        )
      )
      .exec())[0]
  },
  async selectLsolicitudMaterialCount (Vue, idsolicitudMaterial) {
    const tables = Vue.$offline.db.tables
    return (await Vue.$offline.db
      .select(Vue.$offline.db.fn.count(tables.lsolicitud_material.idlsolicitud_material).as('count'))
      .from(tables.lsolicitud_material)
      .where(
        Vue.$offline.db.op.and(
          tables.lsolicitud_material.idsolicitud_material.eq(idsolicitudMaterial),
          tables.lsolicitud_material.estado.gt(0),
        )
      )
      .exec())[0]
  },
}
