<template>
  <b10-base>
    <b10-toolbar
      :title="title"
    >
      <volver-parte-checklist-menu-items
        slot="menuItemsLongPress"
        :idparte-trabajo="routeParams.idparte_trabajo"
      />
    </b10-toolbar>
    <b10-page-content>
      <b10-view-summary
        :item="formattedItem"
        :showing.sync="showingDialogs.moreInfo"
        :loading-data="loadingData"
        :show-alerts="!routeFromBrowserHistory"
      />
      <b10-view-details
        :details="details"
        @click-detail="clickDetail"
      />
      <b10-fab-button
        v-if="hasEditPerm($route.meta.permission.idobjeto)"
        :icon="$vuetify.icons.values.edit"
        @click="clickEdit"
      />
    </b10-page-content>
  </b10-base>
</template>

<script>
import _ from '@/utils/lodash'
import { viewPageMixin } from '@/mixins/viewPageMixin'
import Data from './SolicitudMaterialViewData'
import VolverParteChecklistMenuItems from '../components/VolverParteChecklistMenuItems'
import { SOLICITUD_MATERIAL } from '@/utils/consts'

export default {
  components: {
    VolverParteChecklistMenuItems,
  },
  mixins: [viewPageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  props: {
    editRoute: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      moreInfoRows: [
      ],
      showingDialogs: {
        moreInfo: false,
      },
    }
  },
  computed: {
    formattedItem () {
      if (!_.isEmpty(this.item.dataset)) {
        const item = _.cloneDeep(this.item.dataset)
        item.id = item.solicitud_material.idsolicitudmaterial
        item.subtitle = this.$offline.solicitudMaterial.subtitle(item.solicitud_material)
        item.badge = SOLICITUD_MATERIAL.descripcionEstados[item.solicitud_material.idest_solicitud_material]
        item.badgeColor = SOLICITUD_MATERIAL.coloresEstado[item.solicitud_material.idest_solicitud_material]
        item.moreInfo = this.buildMoreInfo(this.item.metadata)
        return item
      } else {
        return {}
      }
    },
  },
  async created () {
    await this.initStore()
    await this.initDB()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      await this.loadItem()
      this.title = this.$offline.solicitudMaterial.title(this.item.dataset.solicitud_material)
      this.moreInfoRows = [
        { name: 'solicitud_material.nsolicitud_mat', label: 'Número' },
        { name: 'solicitud_material.fsolicitud', label: 'Fecha de solicitud', filter: this.$options.filters.shortDateTime },
        { name: 'solicitud_material.fsolicitada_entrega', label: 'Fecha solicitada de entrega', filter: this.$options.filters.shortDateTime },
        { name: 'solicitud_material.descripcion', label: 'Descripción' },
        { name: 'grupo_empleado.descripcion', label: 'Grupo de empleados gestor' },
        { name: 'solicitud_material.observaciones', label: 'Observaciones' },
        { name: 'solicitud_material.atencion_de', label: 'A la atencion de' },
        { name: 'solicitud_material.idparte_trabajo', label: 'Parte de trabajo' }
      ]
      await this.loadDetailTotals()
    },
    async loadItem () {
      this.loadingData = true
      try {
        const dataset = await Data.selectSolicitudMaterial(
          this, this.routeParams.idsolicitud_material
        )
        if (dataset) {
          this.$set(this.item, 'dataset', dataset)
        } else {
          this.itemNotFound()
        }
      } finally {
        this.loadingData = false
      }
    },
    async loadDetailTotals () {
      this.initDetails()
      const detailLsolicitudMaterial = this.addDetail(
        'listadoMateiral', 'Material solicitado', 'Material solicitado', 'materialInstalar'
      )
      const datasetLsolicitudMaterial = await Data.selectLsolicitudMaterialCount(
        this,
        this.routeParams.idsolicitud_material,
      )
      detailLsolicitudMaterial.badge = datasetLsolicitudMaterial.count || 0

    },
    clickDetail (data) {
      if (data.detail.name === 'listadoMateiral') {
        this.$appRouter.push({
          name: 'offline__parte-trabajo-solicitud-material__material-list',
          params: {
            idsolicitud_material: this.routeParams.idsolicitud_material,
          },
        })
      }
    },
    async clickEdit () {
      this.$appRouter.push({
        name: this.editRoute,
        params: {
          idsolicitud_material: this.routeParams.idsolicitud_material
        },
      })
    },
  },
}
</script>
